import { useFormContext, useController, Control } from "react-hook-form";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type FloorAreaField = Pick<SimulationFormValues, "floor_area">;

type HookReturnType = {
  control: Control<FloorAreaField>;
  loading: boolean | undefined;
  hasError: boolean;
};

export const useFloorAreaFieldField = (): HookReturnType => {
  const { control } = useFormContext<FloorAreaField>();
  const { field, fieldState, formState } = useController({
    name: "floor_area",
    control,
  });
  const loading = useLoadingState();
  const hasError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && field.value === "") ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && field.value === "");

  return {
    control,
    loading,
    hasError,
  };
};
