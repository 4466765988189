import {
  FormControl,
  FormLabel,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { useWidthField } from "./hooks/useWidthField";
import { NumberField } from "../../../../../../../../../components/form/NumberField";

export const WidthField = (): JSX.Element => {
  const { control, loading, hasError } = useWidthField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>ブロック塀</FormLabel>
      <NumberField
        name="block_wall_width"
        control={control}
        hasError={hasError}
        InputProps={{
          startAdornment: <InputAdornment position="start">横</InputAdornment>,
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
      />
    </FormControl>
  );
};
