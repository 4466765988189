import { useFormContext, Control } from "react-hook-form";
import { useLoadingState } from "../../../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../../../SimulationFormValues";

type BlockWallField = Pick<
  SimulationFormValues,
  "block_wall_height" | "block_wall_width"
>;

type HookReturnType = {
  control: Control<BlockWallField>;
  loading: boolean | undefined;
  hasError: boolean;
};

export const useHeightField = (): HookReturnType => {
  const { control, watch } = useFormContext<BlockWallField>();
  const heightField = watch("block_wall_height");
  const widthField = watch("block_wall_width");
  const loading = useLoadingState();
  const hasError = !!(widthField && !heightField);

  return {
    control,
    loading,
    hasError,
  };
};
