import { FormControl, FormLabel, Skeleton } from "@mui/material";
import { NumberField } from "../../../../../../../components/form/NumberField";
import { useFloorAreaFieldField } from "./hooks/useFloorAreaField";

export const FloorAreaField = (): JSX.Element => {
  const { control, loading, hasError } = useFloorAreaFieldField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>建物の大きさ(坪)</FormLabel>
      <NumberField name="floor_area" control={control} hasError={hasError} />
    </FormControl>
  );
};
