import React from "react";
import { useFormContext } from "react-hook-form";
import { SimulationFormValues } from "../../SimulationFormValues";
import { BuildingStoryField } from "./BuildingStoryField";
import { BuildingStructureField } from "./BuildingStructureField";
import { BuildingTypeField } from "./BuildingTypeField";
import { ConstructionRoadSizeField } from "./ConstructionRoadSizeField";
import { DistanceToAdjacentBuildingField } from "./DistanceToAdjacentBuildingField";
import { FloorAreaField } from "./FloorAreaField";
import { PrefectureField } from "./PrefectureField";
import { FieldWrapper } from "../FieldWrapper";

// MEMO: 建物のフィールドと銘打っているが実際にはカーポートや庭石などの撤去物以外のフィールド群
export const BuildingFields = () => {
  const { watch } = useFormContext<SimulationFormValues>();
  const watchFormField = watch();

  return (
    <React.Fragment>
      <FieldWrapper>
        <BuildingTypeField value={watchFormField.building_type} />
      </FieldWrapper>
      <FieldWrapper>
        <BuildingStoryField value={watchFormField.building_stories} />
      </FieldWrapper>
      <FieldWrapper>
        <BuildingStructureField value={watchFormField.building_structure} />
      </FieldWrapper>
      <FieldWrapper>
        <PrefectureField value={watchFormField.prefecture_id} />
      </FieldWrapper>
      <FieldWrapper>
        <ConstructionRoadSizeField
          value={watchFormField.construction_road_size}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DistanceToAdjacentBuildingField
          value={watchFormField.distance_to_adjacent_building}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FloorAreaField />
      </FieldWrapper>
    </React.Fragment>
  );
};
