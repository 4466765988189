import {
  FormControl,
  FormLabel,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { useHeightField } from "./hooks/useHeightField";
import { NumberField } from "../../../../../../../../../components/form/NumberField";

export const HeightField = (): JSX.Element => {
  const { control, loading, hasError } = useHeightField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>ブロック塀</FormLabel>
      <NumberField
        name="block_wall_height"
        control={control}
        hasError={hasError}
        InputProps={{
          startAdornment: <InputAdornment position="start">縦</InputAdornment>,
          endAdornment: <InputAdornment position="end">段積</InputAdornment>,
        }}
        sx={[{ marginRight: 2 }]}
      />
    </FormControl>
  );
};
