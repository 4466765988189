import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from "react-hook-form";

export type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  name: TName;
  control: Control<TFieldValues>;
  hasError?: boolean;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
} & Omit<TextFieldProps, "value" | "defaultValue" | "onChange"> & {
    onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const NumberField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  defaultValue,
  control,
  onChange,
  inputProps,
  hasError,
  rules,
  ...props
}: Props<TFieldValues, TName>) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    field.onChange(newValue);
    if (onChange) onChange(newValue as PathValue<TFieldValues, TName>);
  };

  return (
    <TextField
      {...props}
      type="number"
      name={field.name}
      value={field.value ?? ""}
      inputRef={field.ref}
      onChange={handleChange}
      error={hasError}
      inputProps={{
        ...inputProps,
        inputMode: "numeric",
        pattern: "[0-9]*",
        style: { textAlign: "end", ...inputProps?.style },
      }}
    />
  );
};
